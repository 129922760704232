import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MenuBar from './main/menubar';
import './App.css';

// Lazy load other pages
const About = lazy(() => import('./main/About'));
const Changes = lazy(() => import('./main/changes'));
const Contact = lazy(() => import('./main/Contact'));
const WhatIsWrongWithYoon = lazy(() => import('./main/WhatIsWrongWithYoon'));
const MainPage = lazy(() => import('./main/27F6E510218D1216E064B49691C6967B'));
const RedMainPage = lazy(() => import('./main/288008C178403F22E064B49691C6967B'));
//const oldMain = lazy(() => import('./main/index'));


function App() {

  useEffect(() => {
    // Load the gtag script asynchronously
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-361930884';
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag and set up event snippet
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-361930884');
        gtag('event', 'conversion', {
            'send_to': 'AW-361930884/VRGiCO-1uMEZEITByqwB',
            'value': 1.0,
            'currency': 'KRW'
        });
    };

    // Cleanup the script when the component unmounts
    return () => {
        document.head.removeChild(script);
    };
}, []);


  return (
    <React.StrictMode>
    <Router>
    <Helmet>
        <meta name="description" content="kick-yoon 카운터" />
        <meta property="og:title" content="kick-yoon 카운터" />
        <meta name="twitter:card" content="kick-yoon 카운터" />
        <meta property="og:url" content="https://kick-yoon.com/" />
        <meta name="twitter:url" content="https://kick-yoon.com/" />
        <meta name="twitter:title" content="kick-yoon 카운터" />
        <meta property="og:description" content="kick-yoon 카운터." />
        <meta name="twitter:description" content="kick-yoon 카운터." />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />          
      </Helmet>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/wtf" element={<WhatIsWrongWithYoon />} />
        <Route path="/about" element={<About />} />
        <Route path="/changes" element={<Changes />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/red" element={<RedMainPage />} />
      </Routes>
      <MenuBar />
    </Router>
  </React.StrictMode>
  );
}

export default App;
